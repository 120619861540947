
import { Component, Vue } from 'vue-property-decorator'
import TheProfile from '@/components/profile/TheProfile.vue'
import TheProfilePassword from '@/components/profile/TheProfilePassword.vue'

@Component({
  components: {
    TheProfile,
    TheProfilePassword
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})
export default class ProfilePage extends Vue {
  profile = true

  closeProfile () {
    this.profile = false
  }

  openProfile () {
    this.profile = true
  }
}
