
import "@/assets/styles/imports/profile-password.sass"
import { Component, Vue } from 'vue-property-decorator'
import { UserInterface } from '@/interfaces/user'
import storage from '@/storage'
import api from '@/api'

@Component
export default class TheProfilePassword extends Vue {
  user: UserInterface.User | null = null
  password = {
    old: { value: '', required: false },
    new: { value: '', required: false },
    confirm: { value: '', required: false }
  }
  passwordNotMatch = false

  getUser () {
    this.user = storage.user
  }

  async eventSubmit () {
    if (!this.password.old.value.length) return this.requiredField('old')
    if (!this.password.new.value.length) return this.requiredField('new')
    if (!this.password.confirm.value.length) return this.requiredField('confirm')
    if (this.comparisonPassword()) return
    const { refresh, errors } = await api.authorize.changePassword(this.password.old.value, this.password.new.value)
    if (errors?.length) return alert(this.$t('passerr')) //return alert(errors)
    if (!refresh) return
    alert(this.$t('passok'))
    this.backInProfile()
  }

  requiredField (name: string) {
    this.password[name].required = true
  }

  comparisonPassword () {
    return this.passwordNotMatch = this.password.new.value !== this.password.confirm.value
  }

  backInProfile () {
    this.$emit('back-in-profile')
  }

  created () {
    this.getUser()
  }
}
