
import "@/assets/styles/imports/profile.sass"
import { Component, Vue } from 'vue-property-decorator'
import { UserInterface } from '@/interfaces/user'
import { DictionaryInterface } from '@/interfaces/dictionary'
import Currency = DictionaryInterface.Currency
import Country = DictionaryInterface.Country
import api from '@/api'
import storage from '@/storage'

@Component
export default class TheProfile extends Vue {
  user: UserInterface.User | null = null
  currencies: Array<Currency> = []
  countries: Array<Country> = []

  getUser () {
    this.user = storage.user
  }

  fetchCurrencies () {
    api.dictionary.getCurrency().then(currencies => this.currencies = currencies)
  }

  fetchCountries () {
    api.dictionary.getCountry().then(countries => this.countries = countries)
  }

  async updateProfile () {
    if (!this.user) return
    const { updated, errors } = await api.authorize.update(this.user)
    if (errors?.length) return alert(errors)
    if (!updated) return
    this.getUser()
    await this.$router.push({ name: 'History', params: { lang: this.$route.params.lang }})
  }

  changePassword () {
    this.$emit('change-password')
  }

  created () {
    this.getUser()
    this.fetchCurrencies()
    this.fetchCountries()
  }
}
